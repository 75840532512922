<template>
  <div class="home">
    <section id="screen" class="hero is-fullheight">
      <div class="hero-body">
        <div id="background" class="fixed-parallax"></div>
        <div class="container has-text-centered">
          <img id="logo-vespa" alt="logo" src="../assets/logo.png" />
          <h2 class="subtitle has-text-grey-light mt-5 has-text-shadow-dark">
            La passione per la
            <p class="has-text-light is-inline">Vespa</p>
            fatta club.
          </h2>
        </div>
      </div>
    </section>
    <!-- <VueSlickCarousel :arrows="true" :dots="true">
      <div>1</div>
      <div>2</div>
      <div>3</div>
      <div>4</div>
    </VueSlickCarousel> -->

    <!-- 
    <section class="mb-6" style="margin-top: -10em">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column mx-5">
            <div data-aos="zoom-in-right" class="box has-shadow-dark rounded">
              <article class="media">
                <div class="media-left">
                  <figure class="image is-64x64 is-flex">
                    <img alt="" src="../assets/about.svg" />
                  </figure>
                </div>
                <div class="media-content">
                  <div class="">
                    <p>
                      <strong>Some Name</strong>
                      <small class="is-italic ml-1">@somename</small>
                      <br />
                      Pellentesque nec nam aliquam sem et tortor.Tristique et
                      egestas quis ipsum suspendisse.
                    </p>
                  </div>
                </div>
              </article>
            </div>
          </div>
          <div class="column mx-5">
            <div data-aos="zoom-in-left" class="box has-shadow-dark rounded">
              <article class="media">
                <div class="media-left">
                  <figure class="image is-64x64 is-flex">
                    <img alt=""
                      class="is-flex justify-content-middle"
                      src="../assets/about.svg"
                    />
                  </figure>
                </div>
                <div class="media-content">
                  <div class="">
                    <p>
                      <strong>Some Name</strong>
                      <small class="is-italic ml-1">@somename</small>
                      <br />
                      Libero id faucibus nisl tincidunt eget. Adipiscing elit
                      pellentesque habitant morbi tristique senectus et netus
                      et.
                    </p>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section class="px-5 pt-3 pb-6">
      <div class="is-relative container">
        <div
          id="responsive_headline_welcome"
          class="background-text has-text-grey is-uppercase is-italic"
        >
          vespa club
        </div>
        <div class="mb-6 pb-3 columns is-multiline">
          <div
            data-aos="zoom-in"
            class="column is-12 is-6-desktop mx-auto has-text-centered"
          >
            <h2 class="mb-4 is-size-1 is-size-3-mobile has-text-weight-bold">
              Benvenuto nel
              <p class="has-text-grey-light is-inline">Vespa Club Marostica</p>
            </h2>
            <p v-if="!$store.getters.isLogged" class="subtitle has-text-grey mb-2">
              Tramite il pulsante ISCRIVITI è possibile inviare la tua richiesta di
              iscrizione al Vespaclub Marostica ed inserire tutti i dati necessari per
              diventare Socio del Vespaclub (l’iscrizione come Socio ti sarà abilitata
              solo dopo aver ricevuto la tessera del Club)
              <br />
              <router-link to="/events"
                ><a class="button is-large is-dark mt-5"
                  ><strong class="has-text-light">
                    Iscriviti<span class="icon is-small ml-1">
                      <i class="fas fa-chevron-right"></i> </span
                  ></strong> </a
              ></router-link>
            </p>
          </div>
        </div>
        <div class="columns is-multiline">
          <div class="column is-12 is-4-desktop">
            <div data-aos="fade-right" class="mb-6 is-flex is-horizontal-center">
              <span>
                <router-link to="/events">
                  <div class="button image is-48x48 is-outlined is-dark is-circular">
                    <span class="icon pt-2">
                      <i class="fas fa-calendar-alt"></i>
                    </span></div></router-link
              ></span>
              <div class="ml-3">
                <h4 class="is-size-4 has-text-weight-bold mb-2">Calendario Eventi</h4>
                <p class="subtitle has-text-grey px-3">
                  <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. -->
                </p>
              </div>
            </div>
            <div data-aos="fade-right" class="mb-6 is-flex is-horizontal-center">
              <span>
                <router-link to="/events">
                  <div class="button image is-48x48 is-outlined is-dark is-circular">
                    <span class="icon pt-2">
                      <i class="fas fa-users"></i>
                    </span></div></router-link
              ></span>
              <div class="ml-3">
                <h4 class="is-size-4 has-text-weight-bold mb-2">Raduni ufficiali</h4>
                <p class="subtitle has-text-grey px-3">
                  <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. -->
                </p>
              </div>
            </div>
          </div>
          <div data-aos="zoom-in" class="column is-4 is-block-desktop is-hidden-touch">
            <img
              id="logo_center"
              class="mx-auto mt-0-widescreen is-block image"
              src="../assets/logo_dark.png"
              alt="logo"
            />
          </div>
          <div class="column is-12 is-4-desktop">
            <div data-aos="fade-left" class="mb-6 is-flex is-horizontal-center">
              <span>
                <router-link to="/clothing">
                  <div class="button image is-48x48 is-outlined is-dark is-circular">
                    <span class="icon pt-2">
                      <i class="fas fa-tshirt"></i>
                    </span></div></router-link
              ></span>
              <div class="ml-3">
                <h4 class="is-size-4 has-text-weight-bold mb-2">Abbigliamento</h4>
                <p class="subtitle has-text-grey px-3">
                  <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. -->
                </p>
              </div>
            </div>
            <div data-aos="fade-left" class="mb-6 is-flex is-horizontal-center">
              <span>
                <router-link to="/news">
                  <div class="button image is-48x48 is-outlined is-dark is-circular">
                    <span class="icon pt-2">
                      <i class="fas fa-newspaper"></i>
                    </span></div></router-link
              ></span>
              <div class="ml-3">
                <h4 class="is-size-4 has-text-weight-bold mb-2">Notizie</h4>
                <p class="subtitle has-text-grey px-3">
                  <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. -->
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div
      v-if="banner != null && banner.enabled"
      class="hero is-small"
      v-bind:class="{
        'is-warning': banner.color == 'warning',
        'is-success': banner.color == 'success',
        'is-danger': banner.color == 'danger',
        'is-info': banner.color == 'info',
        'is-dark': banner.color == 'dark',
        'is-light': banner.color == 'light',
      }"
    >
      <div class="hero-body">
        <div class="container has-text-centered">
          <p
            class="tag mr-2"
            v-bind:class="{
              'is-dark': banner.color == 'light',
              'is-light': banner.color != 'light',
            }"
          >
            {{ banner.tag }}
          </p>
          {{ banner.message }}
        </div>
      </div>
    </div>

    <!-- <div id="showcase" class="fixed-parallax"></div> -->

    <section class="has-background-dark">
      <div class="is-relative">
        <div
          id="responsive_headline_join"
          class="background-text has-text-grey is-uppercase is-italic"
        >
          # J o i n
        </div>
        <div class="container my-3">
          <div class="columns is-vcentered container reverse-row-order py-6">
            <div class="column mx-6">
              <h1 class="title has-text-light is-uppercase">
                ISCRIVITI AL PROSSIMO EVENTO
              </h1>
              <p class="has-text-light mb-5">
                Iscriviti al prossimo evento in programma organizzato dal Club. Leggi qui
                tutte le informazioni di cui hai bisogno.
              </p>
              <router-link to="/events"
                ><a class="button is-light"
                  ><strong>
                    Iscriviti<span class="icon is-small ml-1">
                      <i class="fas fa-chevron-right"></i> </span
                  ></strong> </a
              ></router-link>
            </div>
            <div class="column mx-5 py-5">
              <img
                alt="vespe in fila"
                data-aos="fade-left"
                class="cover-image image aspect-ratio rounded has-border-light has-shadow-light"
                src="../assets/about2.jpg"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- <hr class="container has-background-grey-dark" /> -->

      <div class="has-background-grey-dark is-relative">
        <div
          id="responsive_headline_vespa"
          class="background-text has-text-grey is-uppercase is-italic"
        >
          V e s p a
        </div>
        <div class="container my-3">
          <div class="columns is-vcentered container py-6">
            <div class="column mx-5 py-5">
              <img
                alt="persone con in mano i loghi di vespaclub"
                data-aos="fade-right"
                class="cover-image image aspect-ratio rounded has-border-light has-shadow-light"
                src="../assets/manifesti.jpg"
              />
            </div>
            <div class="column mx-6">
              <h1 class="title has-text-light is-uppercase">LA NOSTRA STORIA</h1>
              <p class="has-text-light mb-5">Il nostro viaggio ha avuto inizio…</p>
              <router-link to="/about"
                ><a class="button is-light"
                  ><strong>
                    Scopri<span class="icon is-small ml-1">
                      <i class="fas fa-chevron-right"></i> </span
                  ></strong> </a
              ></router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- <hr class="container has-background-grey-dark" /> -->

      <div class="is-relative">
        <div
          id="responsive_headline_club"
          class="background-text has-text-grey is-uppercase is-italic"
        >
          C l u b
        </div>
        <div class="container my-3">
          <div class="columns is-vcentered container reverse-row-order py-6">
            <div class="column mx-6">
              <h1 class="title has-text-light is-uppercase">Seguici nei social</h1>
              <p class="has-text-light mb-5">
                <span class="icon">
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.facebook.com/vespaclub.marostica/"
                    ><i class="fab fa-2x has-text-light fa-facebook"></i
                  ></a>
                </span>
                &emsp;&emsp;
                <span class="icon">
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://www.instagram.com/vespaclubmarostica/"
                  >
                    <i class="fab fa-2x has-text-light fa-instagram"></i
                  ></a>
                </span>
              </p>
              <!-- <router-link to="/news"
                ><a class="button is-light"
                  ><strong>
                    Vedi le novità<span class="icon is-small ml-1">
                      <i class="fas fa-chevron-right"></i> </span
                  ></strong> </a
              ></router-link> -->
            </div>
            <div class="column mx-5 py-5">
              <img
                alt="grouppo di persone in vespa"
                data-aos="fade-left"
                class="cover-image image aspect-ratio rounded has-border-light has-shadow-light"
                src="../assets/about3.jpg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <div class="has-background-dark">
      <hr class="container has-background-grey-dark" />
    </div> -->

    <!-- <div class="hero is-small has-background-grey-dark py-6">
      <div class="title is-3 is-uppercase has-text-grey">
        I nostri sponsor
      </div>
      <div class="hero-body">
        <div class="columns is-centered has-text-centered">
          <div class="column my-3 is-horizontal-center is-flex">
            <img alt=""
              class="image sponsor greyscale"
              src="../assets/logo_icon.png"
            />
          </div>
          <div class="column my-3 is-horizontal-center is-flex">
            <img alt="" class="image sponsor greyscale" src="../assets/logo.png" />
          </div>
          <div class="column my-3 is-horizontal-center is-flex">
            <img alt="" class="image sponsor greyscale" src="../assets/logo.png" />
          </div>
        </div>
      </div>
    </div> -->

    <Contacts />

    <!-- <section class="py-6 has-background-info">
      <div class="container">
        <div class="is-vcentered columns is-multiline">
          <div class="column px-5 is-6 is-5-desktop mb-4">
            <span class="has-text-white">Tieniti aggiornato!</span>
            <h2
              class="
                has-text-white
                mt-2
                mb-3
                is-size-1 is-size-3-mobile
                has-text-weight-bold
              "
            >
              Iscriviti alla nostra newsletter
            </h2>
            <p class="has-text-white">
              Potrai essere il primo a ricevere gli ultimi aggiornamenti!
            </p>
          </div>
          <div class="column px-5 is-5 ml-auto">
            <div
              data-aos="zoom-in-left"
              class="
                mx-auto
                rounded
                has-border-dark has-shadow-dark
                p-6
                has-background-light has-text-centered
              "
            >
              <h4 class="is-size-5 mb-2 has-text-weight-bold">
                Iscriviti alla newsletter!
              </h4>
              <p class="has-text-grey-dark mb-4">
                Inviando la tua email ci permetterai di aggiornarti con le
                ultime notivà.
              </p>
              <form action="#" @submit.prevent="subscribeEmail">
                <div
                  v-if="ret.message"
                  v-bind:class="{
                    'is-danger': !ret.success,
                    'is-success': ret.success,
                  }"
                  class="notification rounded is-light mb-3"
                >
                  {{ ret.message }}
                </div>
                <p class="control has-icons-left mb-3">
                  <input
                    v-model="form.email"
                    class="input"
                    type="email"
                    placeholder="La tua email"
                  />
                  <span class="icon is-left">
                    <i class="fas fa-envelope"></i>
                  </span>
                </p>
                <button
                  v-bind:disabled="invalidEmail || waiting"
                  v-bind:class="{ 'is-loading': waiting }"
                  class="button is-info is-fullwidth"
                >
                  Invia
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
// import VueSlickCarousel from "vue-slick-carousel";
// import "vue-slick-carousel/dist/vue-slick-carousel.css";
// // optional style for arrows & dots
// import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import A from "../store/action-types.js";
import Generals from "../services/generals";
import Contacts from "../components/Contacts.vue";

export default {
  name: "Home",
  metaInfo: {
    title: "Home",
    // override the parent template and just use the above title only
    // titleTemplate: null,
  },
  data() {
    return {
      form: {
        email: "",
      },
      banner: {
        enabled: false,
        page: "Home",
        pageName: "Home",
        color: "",
        tag: "",
        message: "",
      },
      invalidEmail: true,
      waiting: false,
      ret: {
        success: false,
        message: "",
      },
    };
  },
  components: {
    Contacts,
    // VueSlickCarousel,// TODO
  },
  async created() {
    this.banner = await Generals.getBannerHome();
  },
  mounted() {
    try {
      if (window.fitText != undefined) {
        window.fitText(document.getElementById("responsive_headline_welcome"), 0.4);
        window.fitText(document.getElementById("responsive_headline_join"), 0.4);
        window.fitText(document.getElementById("responsive_headline_vespa"), 0.4);
        window.fitText(document.getElementById("responsive_headline_club"), 0.4);
      }
    } catch (e) {
      console.warn(e);
    }
  },
  watch: {
    "form.email"(value) {
      // binding this to the data value in the email input
      // this.email = value;
      this.validateEmail(value);
    },
  },
  methods: {
    async subscribeEmail() {
      this.ret = {};
      this.waiting = true;
      this.ret = await this.$store.dispatch(A.SUBSCRIBE_EMAIL, {
        email: this.form.email,
      });
      this.waiting = false;
      this.form.email = "";
    },
    validateEmail(value) {
      if (this.$store.getters.regexEmail.test(value)) {
        this.invalidEmail = false;
      } else {
        this.invalidEmail = true;
      }
    },
  },
};
</script>

<style scoped>
#background {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* To compensate for mobile browser address bar space */
  background-image: url("../assets/background.jpg");
  background-size: 100% 100%;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  display: flex;
  z-index: -1;
  filter: blur(2.5px);
}
@media only screen and (max-width: 1023px) {
  #background {
    background-attachment: scroll !important;
  }
}
@media only screen and (min-width: 1023px) {
  #background {
    overflow-y: hidden !important;
  }
}
#logo_center {
  max-height: 180px;
}
#showcase {
  background-image: url("../assets/background_1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 220px;
  filter: blur(3px);
}
.sponsor {
  max-height: 110px;
  max-width: 220px;
  background-size: cover;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#logo-vespa {
  max-width: 500px;
  max-height: 200px;
}
#screen {
  -webkit-animation: fadeIn 1.5s;
  animation: fadeIn 1.5s;
}
</style>
