<template>
  <section class="py-6 px-4 has-background-info">
    <div class="container">
      <div class="is-vcentered columns is-multiline">
        <div class="column px-5 is-6 is-5-desktop mb-2">
          <!-- <span class="has-text-white">Tieniti aggiornato!</span> -->
          <h2
            class="has-text-white mt-2 mb-3 is-size-1 is-size-3-mobile has-text-weight-bold"
          >
            CONTATTI
          </h2>
          <!-- <p class="has-text-white">
              Contatti
            </p> -->
        </div>
        <div class="column px-5 is-5 has-text-left has-text-grey-lighter ml-auto">
          <i class="fa fa-phone greyscale mr-2" aria-hidden="true"></i>
          <strong class="has-text-light">TELEFONO:</strong>
          PRESIDENTE 340 0906704 (Stefano)
          <br />
          VICE-PRESIDENTE 345 7131739 (Eros)
          <br />
          <i class="fa fa-envelope greyscale mr-2" aria-hidden="true"></i>
          <strong class="has-text-light">EMAIL:</strong>
          vespaclubmarostica@gmail.com
          <br />
          <i class="fa fa-map-marker-alt greyscale mr-2" aria-hidden="true"></i>
          <strong class="has-text-light">INDIRIZZO SEDE:</strong>
          Via Stroppari 2, Valle San Floriano di Marostica
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Contacts",
};
</script>

<style scoped></style>
