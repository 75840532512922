import firebase from "../firebase";
import { getDocs, where, addDoc, deleteDoc, doc, query } from "firebase/firestore";

const db = firebase.collection("emails");

class EmailService {
  async getAll() {
    const snapshot = await getDocs(db);
    return snapshot.docs.map(doc => doc.data());
  }

  async isSubscribed(email) {
    const snapshot = await getDocs(query(db, where("email", "==", email)));
    let list = snapshot.docs;
    return !!(list.length > 0 ? list[0] : false);
  }

  async create({ email }) {
    const snapshot = await getDocs(query(db, where("email", "==", email)));
    if (snapshot.docs.length > 0)
      return snapshot.docs[0].ref;
    else
      return await addDoc(db, { email: email });
  }

  async delete(email) {
    const snapshot = await getDocs(query(db, where("email", "==", email)));
    if (snapshot.docs.length > 0)
      return await deleteDoc(doc(db, snapshot.docs[0].id));
    else
      return;
  }
}

export default new EmailService();