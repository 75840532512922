var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hero"},[_c('nav',{staticClass:"navbar py-1",class:{
      'is-dark': _vm.$route.path == '/',
      'is-light': _vm.$route.path != '/',
    },attrs:{"id":"navbar","role":"navigation","aria-label":"main navigation"}},[_c('div',{staticClass:"navbar-brand p-1"},[_c('router-link',{staticClass:"navbar-item",class:{
          'has-background-grey-dark': _vm.$route.name == 'Home',
          'has-background-grey-lighter': _vm.$route.name != 'Home',
        },attrs:{"to":"/"}},[(_vm.$route.name == 'Home')?_c('img',{attrs:{"alt":"logo","src":require("../assets/logo_icon_color.png")}}):_vm._e(),(_vm.$route.name != 'Home')?_c('img',{attrs:{"alt":"logo","src":require("../assets/logo_icon_color_dark.png")}}):_vm._e()]),_c('a',{staticClass:"navbar-burger mr-2",class:{
          'has-border-light': _vm.$route.name == 'Home',
          'has-text-light': _vm.$route.name == 'Home',
          'has-border-dark': _vm.$route.name != 'Home',
          'has-text-dark': _vm.$route.name != 'Home',
        },attrs:{"id":"navbarBurger","role":"button","aria-label":"menu","aria-expanded":"false","data-target":"navbarMenu"}},[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}})])],1),_c('div',{staticClass:"navbar-menu rounded",attrs:{"id":"navbarMenu"}},[_c('div',{staticClass:"navbar-start my-2"},[_c('router-link',{staticClass:"navbar-item has-text-shadow-light",class:{ 'is-active': _vm.$route.name == 'Home' },attrs:{"to":"/"}},[_vm._v("Home")]),_c('router-link',{staticClass:"navbar-item has-text-shadow-light",class:{ 'is-active': _vm.$route.name == 'News' },attrs:{"to":"/news"}},[_vm._v("Notizie")]),_c('router-link',{staticClass:"navbar-item has-text-shadow-light",class:{ 'is-active': _vm.$route.name == 'Events' },attrs:{"to":"/events"}},[_vm._v("Eventi")]),_c('router-link',{staticClass:"navbar-item has-text-shadow-light",class:{ 'is-active': _vm.$route.name == 'About' },attrs:{"to":"/about"}},[_vm._v("Chi siamo")]),_c('div',{staticClass:"navbar-item has-dropdown is-hoverable",class:{ 'is-active': _vm.$route.name == '' }},[_c('a',{staticClass:"navbar-link has-text-shadow-light"},[_vm._v("Altro")]),_c('div',{staticClass:"navbar-dropdown"},[_c('router-link',{staticClass:"has-text-dark navbar-item",attrs:{"to":"/clothing"}},[_vm._v("Abbigliamento")])],1)]),(_vm.$store.getters.isAdmin)?_c('router-link',{staticClass:"navbar-item has-text-shadow-light",class:{
            'is-active': _vm.$route.name == 'Management',
            'has-border-light': _vm.$route.name == 'Home',
            'has-border-dark': _vm.$route.name != 'Home',
          },attrs:{"id":"navbarAdmin","to":"/management"}},[_vm._v("Amministrazione")]):_vm._e()],1),_c('hr',{staticClass:"is-hidden-desktop my-2"}),_c('div',{staticClass:"navbar-end"},[_c('div',{staticClass:"navbar-item"},[(!_vm.$store.getters.isLogged)?_c('div',{staticClass:"columns"},[_c('router-link',{staticClass:"has-text-light column",attrs:{"to":"/register"}},[_c('a',{staticClass:"button is-dark"},[_c('strong',[_vm._v("Registrati")])])]),_c('router-link',{staticClass:"has-text-info column",attrs:{"to":"/login"}},[_c('a',{staticClass:"button is-outlined",class:{
                  'is-dark': _vm.$route.name == 'Home',
                  'is-dark': _vm.$route.name != 'Home',
                }},[_c('strong',[_vm._v("Accedi")])])])],1):_vm._e()]),(_vm.$store.getters.isLogged)?_c('div',{staticClass:"navbar-item"},[_c('div',{staticClass:"navbar-item has-dropdown is-hoverable",class:{
              'has-border-light': _vm.$route.name == 'Home',
              'has-border-dark': _vm.$route.name != 'Home',
              'is-active': _vm.dropdown,
            },attrs:{"id":"navbarProfile"},on:{"touchstart":function($event){_vm.dropdown = !_vm.dropdown}}},[_c('a',{staticClass:"navbar-link has-text-shadow-light",attrs:{"id":"profileButton"}},[(_vm.$store.getters.hasAccess === false)?_c('div',{staticClass:"circle is-inline p-2 mr-2 has-background-danger"}):_vm._e(),(
                  _vm.$store.getters.hasAccess &&
                  !_vm.$store.getters.user.emailVerified
                )?_c('div',{staticClass:"circle is-inline p-2 mr-2 has-background-warning"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$store.getters.username)+" ")]),_c('div',{staticClass:"navbar-dropdown"},[_c('router-link',{staticClass:"has-text-dark navbar-item",attrs:{"to":"/profile"}},[_vm._v("Profilo")]),_c('hr',{staticClass:"navbar-divider"}),_c('div',{staticClass:"has-text-dark navbar-item",attrs:{"id":"logout-button"},on:{"click":_vm.logout}},[_vm._v(" Esci ")])],1)])]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }