import Vue from 'vue'
import App from './App.vue'
import VueMeta from 'vue-meta'
// import './registerServiceWorker'// TODO
import router from './router'
import store from './store'
import firebase from './firebase'
import { onAuthStateChanged } from 'firebase/auth'
import { doc, onSnapshot } from "firebase/firestore";
import VueExpandableImage from 'vue-expandable-image'
import 'bulma'
import Bulma from '@vizuaalog/bulmajs';
import AOS from 'aos';
import 'aos/dist/aos.css'
import Notifications from 'vue-notification'

import M from './store/mutation-types'
import A from './store/action-types'

Vue.config.productionTip = false
Vue.config.devtools = false;

onAuthStateChanged(firebase.auth, async user => {
  if (user) {
    store.commit(M.SET_USER, user);
    store.commit(M.SET_UNSUBSCRIBE_UPDATE_CLAIMS, onSnapshot(doc(firebase.firestoreDB, "users", user.uid), () => {
      store.dispatch(A.RELOAD_USER)
        .then(() => {
        })
        .finally(() => {
          // doens't allow users to re-login or register while logged in
          if (router
            .getRoutes()
            .filter(r => r.meta.blockedWhenLogged)
            .map(r => r.name)
            .includes(router.currentRoute.name)) {
            if (!store.getters.isRegistering) {// fa il redirect solo fuori dalla fase di registrazione (che con "usernamepassword" causa un login -- che causerebbe un redirect non voluto --, seguito da un logout)
              if (router.currentRoute.query.redirect) {
                router.replace({ path: router.currentRoute.query.redirect });
              } else {
                router.replace({ name: "Home" });
              }
            }
          }
        });
    }));
  }
});

Vue.use(Notifications)
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  // ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  // refreshOnceOnNavigation: true
});
Vue.use(VueExpandableImage);

new Vue({
  router,
  store,
  Bulma,
  mounted() {
    AOS.init({
      startEvent: 'load',
      once: true,
      disableMutationObserver: false
    });
    window.addEventListener('load', AOS.refresh);
  },
  render: h => h(App)
}).$mount('#app')
