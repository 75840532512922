<template>
  <div clas="footer">
    <footer class="columns p-5 has-background-black has-text-light">
      <div class="column is-8 is-offset-2">
        <br />
        <nav class="level">
          <div class="level-item column is-4">
            <span class="icon">
              <a
                target="_blank"
                rel="noopener"
                href="https://www.facebook.com/vespaclub.marostica/"
                ><i class="fab has-text-light fa-facebook"></i
              ></a>
            </span>
            &emsp;
            <span class="icon">
              <a
                target="_blank"
                rel="noopener"
                href="https://www.instagram.com/vespaclubmarostica/"
              >
                <i class="fab has-text-light fa-instagram"></i
              ></a>
            </span>
          </div>
          <small class="level-item column is-4">
            &copy; Vespa Club Marostica. Tutti i diritti riservati
            <br />
            <a
              class="has-text-grey-light"
              href="https://www.privacypolicygenerator.info/live.php?token=Uzlm9fErr4B4d81hgdutU6C9g7HZOPS5"
              target="_blank"
              >Privacy Policy</a
            >
          </small>
        </nav>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
};
</script>

<style scoped></style>
