import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/clothing',
    name: 'Clothing',
    component: () => import(/* webpackChunkName: "about" */ '../views/Clothing.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import(/* webpackChunkName: "about" */ '../views/News.vue')
  },
  {
    path: '/events',
    name: 'Events',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Events.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue')
  },
  {
    path: '/management',
    alias: '/users',
    name: 'Management',
    meta: {
      requiresAuth: true,
      adminOnly: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Management.vue')
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      blockedWhenLogged: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      blockedWhenLogged: true,
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: "*",
    component: () => import('../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
})

router.beforeResolve((to, from, next) => {
  // nascondo il menu mostrato da mobile
  document.getElementById("navbarMenu")?.classList.remove("is-active");
  document.getElementById("navbarBurger")?.classList.remove("is-active");
  // -- CONDITIONS
  let isLogged = store.getters.isLogged;
  let isAdmin = store.getters.isAdmin;
  // console.warn(to.fullPath, "logged: " + store.state.user.isLogged)
  if (isLogged) {
    // blockedWhenLogged
    if (to.matched.some(record => record.meta.blockedWhenLogged)) {
      next({ name: 'Home' });
    }
    else {
      if (isAdmin) {
        next()
      }
      else {
        // adminOnly (le pagine con adminOnly devono avere anche requiresAuth: true)
        if (to.matched.some(record => record.meta.adminOnly)) {
          next({ name: 'Home' })
        } else {
          next()
        }
      }
    }
  }
  else {
    // requiresAuth
    if (to.matched.some(record => record.meta.requiresAuth)) {
      next({
        name: 'Login',
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      next()
    }
  }
})

export default router
