import firebase from "../firebase";
import { doc, getDocs, addDoc, setDoc, deleteDoc, getDoc } from "firebase/firestore";

const db = firebase.collection("users");

class UsersService {
  async getRaw(id) {
    return await getDoc(doc(db, id));
  }

  async get(id) {
    const snapshot = await getDoc(doc(db, id));
    return snapshot.data();
  }

  async getAllRaw() {
    return await getDocs(db);
  }

  async create(value) {
    return await addDoc(db, value);
  }

  async update(id, value) {
    return await setDoc(doc(db, id), value, { merge: true });
  }

  async delete(id) {
    return await deleteDoc(doc(db, id));
  }
}

export default new UsersService();