import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getFirestore, collection, collectionGroup } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage, ref } from "firebase/storage";

const configOptions = {
    apiKey: "AIzaSyCKmzL5_FNlZAhsBJJWoGLwAOZq_xKBtnc",
    authDomain: "vespa-club-a1048.firebaseapp.com",
    projectId: "vespa-club-a1048",
    storageBucket: "vespa-club-a1048.appspot.com",
    messagingSenderId: "202994564475",
    appId: "1:202994564475:web:7c19a1022eafe1d34211de",
    measurementId: "G-DKBTGEEJ6D"
};

const firebaseApp = initializeApp(configOptions);
const auth = getAuth(firebaseApp);
const firestoreDB = getFirestore(firebaseApp);
const storageDB = getStorage(firebaseApp);
const messaging = getMessaging(firebaseApp);
const functions = getFunctions(firebaseApp, 'europe-west1');
// connectFirestoreEmulator(firestoreDB, 'localhost', 8080);
// connectFunctionsEmulator(functions, 'localhost', 5001);
// connectStorageEmulator(storageDB, 'localhost', 9199);
// connectAuthEmulator(auth, 'localhost', 9099);

export default {
    firebaseApp: firebaseApp,
    auth: auth,
    firestoreDB: firestoreDB,
    storageDB: storageDB,
    functions: functions,
    httpsCallable: (name) => httpsCallable(functions, name, { timeout: 30000 }),
    messaging: messaging,
    getToken: () => getToken(messaging, { vapidKey: 'BHi3T7yjVosvtq9nuEjw5l7AbgOxtTBoeNrDcZwklUDyTHTgcV3yxDgp6TNFHRO7bTFAwd6q4JZoGQIFJa1KDeg' }),
    onMessage: (obs) => onMessage(messaging, obs),
    ref: (name) => ref(storageDB, name),
    collection: (name, ...pathSegments) => collection(firestoreDB, name, ...pathSegments),
    collectionGroup: (name, ...pathSegments) => collectionGroup(firestoreDB, name, ...pathSegments)
};